<template>
  <v-container fluid>

    <v-row>
      <!--menu-->
      <v-col cols="12" sm="12" md="3">
        <b-card :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="mb-1" no-body>
          <b-card-header class="p-0" header-tag="header" role="tab">
            <v-list-item @click="opened =! opened" class="ma-0"
                         v-b-toggle.global-settings>
              <v-list-item-title :class="[this.$vuetify.theme.dark? '' : '', this.opened? 'primary--text text-wrap' : 'text-wrap']">
                {{ this.$t('kiosk.lang_kiosk_interface') }}
              </v-list-item-title>
              <v-list-item-action>
                <v-icon v-if="!this.opened">keyboard_arrow_down</v-icon>
                <v-icon v-if="this.opened">keyboard_arrow_up</v-icon>
              </v-list-item-action>
            </v-list-item>
          </b-card-header>
          <b-collapse :visible="this.opened" accordion="global-settings" id="global-settings"
                      role="tabpanel">
            <b-card-body class="pa-0">
              <b-list-group flush>
                <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'colors'? 'primary--text' : '']"
                                   @click="handleClick('colors')"
                                   class="items">
                  {{ this.$t('tablebee.lang_theme_colors') }}
                </b-list-group-item>
                <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'text_colors'? 'primary--text' : '']"
                                   @click="handleClick('text_colors')"
                                   class="items">
                  {{ this.$t('tablebee.lang_text_settings') }}
                </b-list-group-item>
                <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'dummy_images'? 'primary--text' : '']"
                                   @click="handleClick('dummy_images')"
                                   class="items">
                  {{ this.$t('kiosk.lang_kioskDummyImages') }}
                </b-list-group-item>
                <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'front_page'? 'primary--text' : '']"
                                   @click="handleClick('front_page')"
                                   class="items">
                  {{ this.$t('kiosk.lang_frontPageContent') }}
                </b-list-group-item>
                <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'front_page_templates'? 'primary--text' : '']"
                                   @click="handleClick('front_page_templates')"
                                   class="items">
                  {{ this.$t('kiosk.lang_frontPageTemplates') }}
                </b-list-group-item>
                <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'layout_settings'? 'primary--text' : '']"
                                   @click="handleClick('layout_settings')"
                                   class="items">
                  {{ this.$t('kiosk.lang_layoutSettings') }}
                </b-list-group-item>

               <!--
                <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'images'? 'primary--text' : '']"
                                   @click="handleClick('images')"
                                   class="items">
                  {{ this.$t('tablebee.lang_images') }}
                </b-list-group-item>

                <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'messages'? 'primary--text' : '']"
                                   @click="handleClick('messages')"
                                   class="items">
                  {{ this.$t('generic.lang_gui_messages') }}
                </b-list-group-item>


                <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'privacy'? 'primary--text' : '']"
                                   @click="handleClick('privacy')"
                                   class="items">
                  {{ this.$t('tablebee.lang_privacy_and_allergens') }}
                </b-list-group-item>


                <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'extra_css'? 'primary--text' : '']"
                                   @click="handleClick('extra_css')"
                                   class="items">
                  {{ this.$t('tablebee.lang_extra_css') }}
                </b-list-group-item>
               -->
                <!--
                <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'live_preview'? 'primary--text' : '']"
                                   @click="handleClick('live_preview')"
                                   class="items">
                  {{ this.$t('generic.lang_preview') }}
                </b-list-group-item>
                -->
              </b-list-group>
            </b-card-body>
          </b-collapse>
        </b-card>
      </v-col>

      <!--content-->
      <v-col cols="12" sm="12" md="9">
        <v-card>
          <v-card-text class="ma-0 pa-0">
            <v-container class="ma-0 pa-0">
              <v-row no-gutters>
                <v-col>
                  <template>
                    <div role="tablist">
                      <kiosk-colors-component v-if="this.currentTab === 'colors'"/>
                      <kiosk-text-colors-component v-if="this.currentTab === 'text_colors'"/>

                      <!-- start images -->
                      <kiosk-design-images-component v-if="this.currentTab === 'images'"/>
                      <!-- end images -->

                      <kiosk-design-dummy-images-component v-if="this.currentTab === 'dummy_images'"/>

                      <KioskFrontPageContent v-if="this.currentTab === 'front_page'"/>


                      <KioskFrontPageTemplates v-if="this.currentTab === 'front_page_templates'"/>

                      <!-- start of layout -->
                      <KioskLayoutSettingsComponent v-if="this.currentTab === 'layout_settings'"/>
                      <!-- end of layout -->

                      <!-- start of messages -->
                      <kiosk-design-messages-component v-if="this.currentTab === 'messages'"/>
                      <!-- end of messages -->

                      <!-- start of privacy -->
                      <kiosk-design-privacy-component v-if="this.currentTab === 'privacy'"/>
                      <!-- end of privacy -->

                      <!--start: extra css-->
                      <kiosk-extra-css-component v-if="this.currentTab === 'extra_css'"/>
                      <!--end: extra css-->


                      <!-- start preview -->
                      <kiosk-design-preview-component v-if="this.currentTab === 'live_preview'"/>
                    </div>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <!--          <v-card-actions v-if="this.currentTab !== 'live_preview'">
                      <v-col cols="12" align="end">
                        <v-btn color="primary">
                          Rest Default Config
                        </v-btn>
                      </v-col>

                    </v-card-actions>-->
        </v-card>
      </v-col>
    </v-row>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                          :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidth"
                          id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
    </div>
  </v-container>
</template>

<script>
import mixin from "../../../mixins/KeyboardMixIns";
import ImageCropper from "../../common/imagecropper";
import {ENDPOINTS} from '@/config';
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor} from 'vue-quill-editor';
import CountryFlag from "vue-country-flag";
import {createNamespacedHelpers} from "vuex";
import KioskColorsComponent from "@/components/kiosk/design/KioskColorsComponent";
import KioskTextColorsComponent from "@/components/kiosk/design/KioskTextColorsComponent";
import KioskDesignImagesComponent from "@/components/kiosk/design/KioskDesignImagesComponent";
import KioskDesignDummyImagesComponent from "@/components/kiosk/design/KioskDesignDummyImagesComponent";
import KioskDesignMessagesComponent from "@/components/kiosk/design/KioskDesignMessagesComponent";
import KioskDesignPrivacyComponent from "@/components/kiosk/design/KioskDesignPrivacyComponent";
import KioskDesignPreviewComponent from "@/components/kiosk/design/KioskDesignPreviewComponent";
import KioskExtraCssComponent from "@/components/kiosk/design/KioskExtraCssComponent";
import KioskFrontPageContent from "../settings/KioskFrontPageContent.vue";
import KioskFrontPageTemplates from "./frontpage/KioskFrontPageTemplates.vue";
import KioskLayoutSettingsComponent from "@/components/kiosk/design/KioskLayoutSettingsComponent.vue";

export default {
  name: "KioskDesignComponent",
  components: {
    KioskFrontPageContent,
    KioskExtraCssComponent,
    KioskDesignPreviewComponent,
    KioskDesignPrivacyComponent,
    KioskDesignMessagesComponent,
    KioskDesignImagesComponent,
    KioskDesignDummyImagesComponent,
    KioskTextColorsComponent, 
    KioskColorsComponent, 
    quillEditor, 
    ImageCropper, 
    CountryFlag,
    KioskFrontPageTemplates,
    KioskLayoutSettingsComponent
  },
  mixins: [mixin],
  data() {
    return {
      currentTab: "colors",
      ENDPOINTS,
      selected_language_tab: "de",
      loading: false,
      loadingLayout: false,
      opened: true,
      data: {
        available_fonts: [
          'Arial',
          'Verdana',
          'Helvetica',
          'Tahoma',
          'Trebuchet MS',
          'Times New Roman',
          'Georgia',
          'Garamond',
          "Pacifico",
          "Dancing Script",
          "Shadows Into Light",
          "Lobster",
          "Anton",
          "Indie Flower",
          "Charmonman",
          "Kodchasan",
          "Notable",
          "Mali",
          "Srisakdi",
          "Slabo 27px"
        ]
      },
      conditions: {
        privacy: "",
        privacy_en: "",
        privacy_fr: "",
        privacy_ar: "",
        allergens: "",
        allergens_en: "",
        allergens_fr: "",
        allergens_ar: ""
      },


      quillOptions: {

        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            [{'align': []}],
            [{'font': []}],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ],
        },
      },
      layout: {},

    }
  },
  computed: {
    ...createNamespacedHelpers("settings").mapGetters([
      "getSettingValue"
    ]),
    isTranslationEnabled() {
      return parseInt(this.getSettingValue("enable_translation")) === 1;
    }
  },
  methods: {
    handleClick(newTab) {
      if (newTab && newTab.length > 0) {
        this.currentTab = newTab;
        this.$router.push({query: {tab: newTab}})
      }
    },


  },
  mounted() {
    this.handleClick(this.$route.query.tab)
  },
}
</script>

<style scoped>
.dropdown-menu-header {
  z-index: 1 !important;
}

#editor1, #editor2, #editor3, #editor4, #editor5, #editor6, #editor7 {
  height: 140px;
  width: 100%;
}

#privacy_editor, #privacy_editor_2, #privacy_editor_3, #privacy_editor_4, #privacy_editor_8, #allergens_editor, #allergens_editor_2, #allergens_editor_3 {
  height: 160px;
}

.items {
  cursor: pointer;
  user-select: none;
}
</style>
