<template>
  <b-card no-body class="mb-1 transparent">
    <b-card-header
      header-tag="header"
      v-b-toggle.accordion3
      :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'p-1']"
      role="tab"
    >
      <v-row>
        <v-col>
          <div class="pa-5 d-block">
            {{ this.$t("kiosk.lang_kioskDummyImages") }}
          </div>
        </v-col>
      </v-row>
    </b-card-header>

    <b-card-body>
      <v-row>
        <v-col cols="12" sm="6">
          <div
            :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
            class="main-card mb-3 card shadow-sm"
          >
            <div class="pa-0 ma-0 dropdown-menu-header">
              <div class="card-header bg-info text-white">
                {{ this.$t("generic.lang_forItemgroup") }}
              </div>
            </div>
            <div class="card-body">
              <image-cropper
                ref="itemgroup_image"
                v-model="image.itemgroup_image"
              />
            </div>
            <div class="d-block text-right card-footer pa-0">
              <v-btn
                :disabled="loaders.itemgroup_image"
                :loading="loaders.itemgroup_image"
                @click="upload('itemgroup_image')"
                block
                :class="[this.$vuetify.theme.dark ? '' : 'text-dark']"
                class="btn bg-muted mx-auto mt-0 mb-0"
                large
              >
                <v-icon class="ma-1">cloud_upload</v-icon>
                {{ this.$t("generic.lang_hochladen") }}
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <div
            :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
            class="main-card mb-3 card shadow-sm"
          >
            <div class="pa-0 ma-0 dropdown-menu-header">
              <div class="card-header bg-info text-white">
                {{ this.$t("generic.lang_forItem") }}
              </div>
            </div>
            <div class="card-body">
              <image-cropper ref="item_image" v-model="image.item_image" />
            </div>
            <div class="d-block text-right card-footer pa-0">
              <v-btn
                :disabled="loaders.item_image"
                :loading="loaders.item_image"
                @click="upload('item_image')"
                block
                :class="[this.$vuetify.theme.dark ? '' : 'text-dark']"
                class="btn bg-muted mx-auto mt-0 mb-0"
                large
              >
                <v-icon class="ma-1">cloud_upload</v-icon>
                {{ this.$t("generic.lang_hochladen") }}
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col cols="6" md="6">
          <div
            :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
            class="main-card mb-3 card shadow-sm"
          >
            <div class="pa-0 ma-0 dropdown-menu-header">
              <div class="card-header bg-info text-white">
                {{ "generic.lang_forGarnish" }}
              </div>
            </div>
            <div class="card-body">
              <image-cropper
                ref="garnish_image"
                v-model="image.garnish_image"
              />
            </div>
            <div class="d-block text-right card-footer pa-0">
              <v-btn
                :disabled="loaders.garnish_image"
                :loading="loaders.garnish_image"
                @click="upload('garnish_image')"
                block
                :class="[this.$vuetify.theme.dark ? '' : 'text-dark']"
                class="btn bg-muted mx-auto mt-0 mb-0"
                large
              >
                <v-icon class="ma-1">cloud_upload</v-icon>
                {{ this.$t("generic.lang_hochladen") }}
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import ImageCropper from "@/components/common/imagecropper";

export default {
  name: "KioskDesignImagesComponent",
  components: {
    ImageCropper,
  },
  data() {
    return {
      loading: false,
      loaders: {
        itemgroup_image: false,
        item_image: false,
        garnish_image: false,
      },
      image: {
        itemgroup_image: "",
        item_image: "",
        garnish_image: "",
      },
    };
  },
  methods: {
    getImages() {
      this.loading = true;

      this.axios
        .post(ENDPOINTS.KIOSK.SETTINGS.DESIGN.DUMMY_IMAGES.GET)
        .then((res) => {
          if (res.status === 200) {
            this.image.itemgroup_image =
              res.data.image.itemgroup_image === "data:image/;base64,"
                ? null
                : res.data.image.itemgroup_image;
            this.image.item_image =
              res.data.image.item_image === "data:image/;base64,"
                ? null
                : res.data.image.item_image;
            this.image.garnish_image =
              res.data.image.garnish_image === "data:image/;base64,"
                ? null
                : res.data.image.garnish_image;
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred") + " " + err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    upload(type) {
      // let imageType = "";
      // switch (type) {
      //   case 1:
      //     imageType = "itemgroup_image";
      //     break;
      //   case 2:
      //     imageType = "item_image";
      //     break;
      //   case 3:
      //     imageType = "garnish_image";
      //     break;
      // }

      this.$refs[type].cropImage();
      this.loaders[type] = true;

      this.axios
        .post(ENDPOINTS.KIOSK.SETTINGS.DESIGN.DUMMY_IMAGES.UPDATE, {
          upload: this.image[type],
          type: type,
        })
        .then((res) => {
          if (res.data.status === "SUCCESS") {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_actionSuccessful"),
              color: "success",
            });
          } else {
            Events.$emit("showSnackbar", {
              message: res.data.msg || res.data.status,
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loaders[type] = false;
        });
    },
  },
  mounted() {
    this.getImages();
  },
};
</script>

<style scoped></style>
